.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.logo {
    margin: 0px;
}

.ra-rich-text-input {
    min-height: 200px;
}

.App-intro {
    font-size: large;
}

.MuiDrawer-root.MuiDrawer-docked {
    background-color: #222;
}

.ra-input {
    margin: 10px 0px;
}

.MuiMenuItem-root {
    color: #999 !important;
    text-transform: uppercase;
    padding: 10px 15px !important;
    border-top: 1px solid #999 !important;
    border-bottom: 0px solid #efefef;
}

.MuiMenuItem-root:hover {
    background-color: #20a8d8 !important;
    color: #000 !important;
}

.MuiMenuItem-root .MuiTypography-colorTextSecondary {
    color: #fff !important;
    text-transform: uppercase;
}

.MuiListItemIcon-root {
    color: #ccc !important;
    text-transform: uppercase;
}

.MuiFormControl-root.ra-rich-text-input>.MuiFormLabel-root {
    padding: 10px;
}

.MuiFormControl-root.ra-rich-text-input .ql-toolbar.ql-snow {
    margin-bottom: 0px;
    border: 1px solid #ccc;
    padding: 10px;
}

.MuiFormControl-root.ra-rich-text-input .outlined.ql-container.ql-snow {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.Layout-content-4 .MuiToolbar-root.MuiToolbar-regular {
    background-color: #dedede;
    border: 1px solid #cdcdcd;
    padding: 0px 15px;
    margin-bottom: 2px;
}

.Layout-content-4 .MuiToolbar-root.MuiToolbar-regular>.MuiToolbar-root.MuiToolbar-regular {
    background-color: #dedede;
    border: 0px solid #cdcdcd;
    padding: 16px 0px 0px 0px;
    margin-bottom: 0px;
}

.main-logo {
    height: 30px;
}

.MuiDrawer-docked {
    min-height: 100vh;
}

.create-page {
    padding: 0px 24px;
}

.list-page {
    padding: 0px 24px;
}

.list-page img {
    max-height: 60px;
}

.edit-page {
    padding: 0px 24px;
}

.MuiDrawer-root .edit-page {
    padding: 0px;
}

.MuiDrawer-root .create-page {
    padding: 0px;
}

.logourl>div>div {
    background-color: #efefef;
    border: 1px solid #ccc;
}

.ra-rich-text-input .outlined .ql-editor {
    min-height: 150px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    transform: translate(12px, -5px) scale(0.75) !important;
}

.full-screen-loading {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #efefef;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.full-screen-loading>div {
    margin: 5px 0px;
}

.test-not-allowed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #efefef;
    z-index: 9990;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.candidate-search-parent{
 display:inline-block;
 margin-bottom:20px;
}
.candidate-search-parent .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
}
.candidate-search-parent .MuiOutlinedInput-input {
    padding: 9.5px 14px;
}
.candidate-search-parent button {
    margin-left:20px;
}
.load-more-btn {
    margin: auto;
    padding: 10px;
    text-align: center;
    background-color: blue;
    display: flex;
    width: 120px;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    color: #fff;
    border: 2px solid #000000;
}

.not-allowed-message {
    font-size: 26px;
    color: green;
    font-weight: bold;
    text-align: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.test-review-container{
    padding:10px;
    width: 80%;
    margin: 0px auto;
    height: calc(100vh - 120px);
    overflow: auto;
}
.test-question-row{
    display:flex;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}
.test-question{
    flex: 1;
    display:flex;
    border-right: 1px solid #aaa;
    padding: 10px;
}
.question-option-selected{
    flex:1;
    display:flex;
    padding: 10px;
}
.question-image{
    width:auto;
    max-width: 95%;
    max-height: 200px;
}
.option-image{
    width:auto;
    max-width: 95%;
    max-height: 100px;
}
.review-caption{
    text-align: center;
    padding:10px;
    font-weight: bold;
}
.test-review-container .caption{
    font-weight: bold;
    padding:10px;
}
.no-user-image{
    text-align:center;
    padding:20px;
    border:1px solid #ccc;
    width:50%;
}
.test-details-btn{
    background-color: #3f51b5;
    padding:10px 20px;
    color:#fff;
    cursor: pointer;
}
.test-details{
    padding:55px 10px;
}